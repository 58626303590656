.menu {
  position: fixed;
  right: 0;
  top: 0;
  height: 100dvh;
  z-index: 1000;
  background-color: rgb(20, 20, 20);
  color: lightgray;
  padding-top: 15px;
  padding-right: 25px;
}

.menuCollapsed {
  display: none;
}

.menuToggle {
  cursor: pointer;
  float: right;
}

.menuLinks {
  padding: 50px 10px 0px 30px;
}

@media (min-width: 576px) {
  .menu {
    display: none;
  }
}
