@import "variables.scss";
@import "fonts.scss";

body {
  margin: 0;
  font-family: Nunito, "Trebuchet MS", Arial, Helvetica;

  &.light {
    background-color: $theme-background;
  }

  &.dark {
    background-color: $theme-dark-background;
    color: $theme-dark-text;
  }
}

header {
  height: $nav-compact;
  background-color: $theme-primary;
  position: sticky;
  top: 0;

  .title {
    font-size: 18px;
    font-weight: 700;
    color: $nav-text;
    margin-left: 10px;
  }

  .logo {
    width: $nav-compact;
    text-align: center;

    img {
      width: 80%;
      border-radius: 50%;
    }
  }
}

footer {
  width: 100%;
  margin-top: 30px;
  padding: 30px;
  background-color: $theme-primary;
  color: $nav-text;
}

.side-panel {
  height: calc(100dvh - $nav-compact);
  line-height: 50px;

  .side-panel-left {
    width: $nav-compact;
    background-color: $theme-primary;
    text-align: center;
    padding-top: 40px;
  }

  .side-panel-right {
    width: $nav-full - $nav-compact;
    background-color: $theme-secondary;
    padding-top: 40px;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.nav-icon {
  font-size: 16px;
  color: gray;
}

.nav-icon:hover {
  color: white;
}

.nav-link,
.nav-link-active {
  font-weight: 700;
  color: $nav-text;
  margin-left: 10px;
  padding-bottom: 2px;
  background-image: linear-gradient($theme-accent, $theme-accent);
  background-size: 0% 3px, 100% 3px;
  background-position-x: 0%;
  background-position-y: 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.nav-link-active {
  background-image: linear-gradient($theme-accent, $theme-accent),
    linear-gradient($theme-accent, $theme-accent);
}

.nav-link:hover,
.nav-link-active:hover {
  color: white;
  background-size: 100% 3px;
}

  .container-fluid {
    padding: 20px;
  padding-left: $nav-full + 25px;

  @media (max-width: 768px) {
    padding-left: $nav-compact + 20px;
  }
  @media (max-width: 576px) {
    padding-left: 15px;
  }
}

.content {
  max-width: 850px;

  @media (max-width: 576px) {
    min-height: calc(100dvh - $nav-compact - 70px);
  }
}

aside {
  position: sticky;
  top: $nav-compact + 20px;
  margin-top: 40px;
  margin-left: 20px;
  max-width: 300px;

  @media (max-width: 1200px) {
    display: none;
  }
}

.callout {
  padding: 15px;
  padding-bottom: 10px;
  margin: 15px 0;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  border-left-width: 5px;
  border-left-color: $theme-accent;
  border-radius: 10px;

  .dark & {
    background-color: $theme-dark-background2;
    border-width: 0 0 0 5px;
  }

  .light & {
    background-color: $theme-background2;
    box-shadow: 1px 1px 3px lightgray;
  }
}

.login-link {
  color: $nav-text;
}

.login-link:hover {
  color: white;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:focus {
  outline: 0;
}

.tag,
.tag:hover {
  border-radius: 10px;
  background-color: $theme-accent;
  padding: 0px 10px;
  line-height: 24px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 700;
  color: white;
}

.bullet {
  color: $theme-accent;
  margin-right: 8px;
  transition: 0.3s ease all;
}

.animate-bullet:hover .bullet {
  margin-left: -10px;
  padding-right: 10px;
}

.vr {
  flex-shrink: 0;
}

.text-small {
  font-size: $font-small;
}

.about-image {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 8px;
  float: left;
}

.project-image {
  width: 120px;
  height: 90px;
  border: 2px solid;
  border-color: lightgray;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 60px;
    height: 45px;
  }
}

.employer-logo {
  width: 55px;
  height: 45px;
  border: 2px solid;
  border-color: lightgray;
  margin-bottom: 15px;
  margin-right: 15px;
}

hr {
  border: dashed;
  border-width: 1px;
}

.mobile-only {
  @media (min-width: 576px) {
    display: none !important;
  }
}

.mobile-hidden {
  @media (max-width: 576px) {
    display: none !important;
  }
}

p {
  white-space: pre-line;
}

.skillList {
  max-width: 200px;
}

.progress-bar {
  animation-name: animateProgress;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.dark .progress {
  background-color: $theme-dark-background2;
}

@keyframes animateProgress {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.contact-form {
  max-width: 500px;
}

.divider {
  border-right: 1px solid lightgray;
}

.input-button {
  background-color: white;
}

.dark .form-control,
.dark .input-button {
  background-color: $theme-dark-background2;
  color: lightgray;
  border: 0;
  box-shadow: none;
}

.dark .page-item .page-link {
  background: $theme-dark-background;
  border-color: $theme-dark-background2;
}

.dark img {
  border: 0;
}
