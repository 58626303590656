@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/Nunito-Bold.ttf) format("truetype");
}
